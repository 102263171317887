<template>
	<div>
		<!-- top image bar -->
		<!-- <Header :propsObj="page"></Header> -->

		<!-- at home - logo button - at school -->
		<!-- <HomeSchoolBar></HomeSchoolBar> -->

		<!-- introduction -->
		<!-- <Intro :title="page.intro_title" :content="page.intro_content"></Intro> -->

		<!-- content -->
		<div class="container">
			<div class="row">
				<div class="col">
					<h1 class="text-center my-5">Winkelwagen</h1>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<h4>Overzicht</h4>
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Omschrijving</th>
								<th>Prijs</th>
								<th>Opm</th>
								<th>Aantal</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in cart" :key="item.id">
								<td class="w-60 linewrap" :class="{ 'font-weight-bold': item.remarks == '*' }">
									{{ item.description }}
								</td>
								<td class="linewrap">{{ item.price }}</td>
								<td :class="{ 'text-white': item.remarks == '*' }">{{ item.remarks }}</td>
								<td class="w-10">
									<a @click="quantity(item, 1)">
										<v-icon name="plus-square"></v-icon>
									</a>
									<span class="px-3">{{ item.quantity }}</span>
									<a @click="quantity(item, -1)">
										<v-icon name="minus-square"></v-icon>
									</a>
								</td>
							</tr>
							<tr>
								<td>
									<h5>Totaal van artikelen ({{ totalNr }})</h5>
								</td>
								<td></td>
								<td></td>
								<td>
									<h5>{{ totalPrice | toCurrency }}</h5>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="row my-4">
				<div class="col-md-6">
					<h4>Bestelgegevens</h4>
					<Form :info="{ totalPrice: totalPrice }"></Form>
				</div>
			</div>
		</div>

		<!-- footer  -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
import axios from 'axios';
import utils from '@/js/utils';

import Form from '@/webshop/OrderForm.vue';
// import Footer from '@/components/Footer.vue';

import 'vue-awesome/icons/plus-square';
import 'vue-awesome/icons/minus-square';
import Icon from 'vue-awesome/components/Icon';

export default {
	data() {
		return {
			cart: [],
			totalPrice: 0,
			totalNr: 0,
		};
	},

	props: {
		route: String,
	},

	components: {
		Form,
		// Footer,
		'v-icon': Icon,
	},

	created: function () {
        console.clear();
        
		// read from local storage
		if (sessionStorage.getItem('cart')) {
			this.cart = JSON.parse(sessionStorage.getItem('cart'));
			this.calcTotals();
		}
	},

	filters: {
		toCurrency: function (value) {
			if (typeof value !== 'number') {
				return value;
			}
			var formatter = new Intl.NumberFormat('nl-NL', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
			});
			return formatter.format(value);
		},
	},

	methods: {
		quantity(item, nr) {
			if (nr > 0 || (nr < 1 && item.quantity)) {
				item.quantity += nr;
				this.calcTotals();
			}
		},

		calcTotals() {
			this.totalPrice = 0;
			this.totalNr = 0;
			for (let i = 0; i < this.cart.length; i++) {
				let currency = this.cart[i].price;
				let quantity = this.cart[i].quantity;
				currency = currency.replace(',', '.');
				var number = Number(currency.replace(/[^0-9.-]+/g, ''));
				this.totalNr += quantity;
				this.totalPrice += number * quantity;
			}
		},
	},
};
</script>

<style scoped>
.linewrap {
	white-space: pre-line;
}

.w-60 {
	width: 60%;
}

.w-10 {
	width: 10%;
}

a {
	color: #be1622;
}

a:hover {
	color: #f39200;
	text-decoration: none;
}
</style>
